
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class UserLogin extends Vue {

    // credentials
    private email: string = "";

    public mounted() {
        let inputEmail = document.getElementById("inputEmail");
        inputEmail?.addEventListener("keydown", (e) => {
            if ((e.key === "Enter")||(e.code === "Enter")) {
                this.login();
            }
        });
    }

    // BUTTONS
    public async login() {
        // GET BUTTON
        const btnLogin = document.getElementById("btnLogin");
        // SHOW LOADING
        btnLogin?.classList.add("is-loading");
        
        try {
            // PRE LOGIN
            let allowed = await this.$store.dispatch("preLogin", this.email);
            if (allowed) {
                // ACTUAL LOGIN
                await this.$store.dispatch("login", this.email);
                // CLEAR EMAIL
                this.email = "";
            }
            else {
                // TODO: notify the customer
            }
        }
        catch (error) {
            console.log("ERROR", error);
        }

        // LOADING COMPLETE
        btnLogin?.classList.remove("is-loading");
    }
}
