// import vue
import Vue from 'vue';
// import vuex (store)
import Vuex from 'vuex';

// import magic-sdk
import { Magic } from 'magic-sdk';

// import router
import router from "../router/index";

// create magic  // TODO: Note
const magic = new Magic("pk_live_0564E859244BC179");

// setup vue to use vuex (store)
Vue.use(Vuex);

// export
export default new Vuex.Store({
    state: {
        // GARBAGE // TODO: remove
        page: "home",
        subpage: "basic",
        // serverAddress: "",
        // serverAddress: "http://192.168.1.222:3000/",
        serverAddress: "https://nekeyto.com/",
        initialLoad: true,
        activeCategoryId: null,

        // MY CATEGORIES
        myCategoriesCache: {
            list: []
        },

        // AUTH
        auth: {
            user: null,
            key: null,
            categoryId: null
        },
        freshLogin: true,

        // APPLICATION DATA

        // PAGE DATA
        pages: {
            // CURRENT PAGE
            current: "home",
            // DATA PER PAGE
            alert: {
                components: ["AlertList"]
            },
            customer: {
                components: ["CustomerList"]
            },
            help: {
                components: []
            },
            home: {
                components: []
            },
            negativeAction: {
                components: []
            },
            negativeKeyword: {
                components: []
            },
            nekeyto: {
                components: []
            },
            status: {
                components: ["StatusList", "StatusStatistics"]
            },
            user: {
                components: []
            }
        },

        // ALERT CACHE
        alertCache: {
            alertId: null,
            alert: null,
            alertList: [],
        },
        // CAMPAIGN CACHE
        campaignCache: {
            campaignId: null,
            campaign: null,
            campaignList: []
        },
        // CAMPAIGN TYPE CACHE
        campaignTypeCache: {
            campaignTypeId: null,
            campaignType: null,
            campaignTypeList: []
        },
        // CATEGORY CACHE
        categoryCache: {
            categoryId: null,
            category: null,
            categoryList: []
        },
        // CUSTOMER CACHE
        customerCache: {
            customerId: null,
            customer: null,
            customerList: [],
        },
        // NEGATIVE ACTION CACHE
        negativeActionCache: {
            negativeActionId: null,
            negativeAction: null,
            negativeActionList: []
        },
        // NEKEYTO CACHE
        nekeytoCache: {
            nekeytoList: []
        },
        // SEARCH TERM CACHE
        searchTermCache: {
            searchTermId: null,
            searchTerm: null,
            searchTermList: []
        },
        // STATUS CACHE
        statusCache: {
            statusId: null,
            status: null,
            statusList: [],
            statusStatistics: null
        },
        // UNIQUE SEARCH TERM
        uniqueSearchTermCache: {
            uniqueSearchTermId: null,
            uniqueSearchTerm: null,
            uniqueSearchTermList: []
        },
        // AD GROUP CACHE
        adGroupTypeCache: {
            adGroupTypeId: null,
            adGroupType: null,
            adGroupTypeList: []
        },
        // SEARCH TERM CACHE
        masterListCache: {
            masterListId: null,
            masterList: null,
            masterListList: []
        },
    },
    mutations: {
        // NAVIGATION
        changePage (state, page) {
            state.page = page;
        },
        changeSubpage (state, subpage) {
            state.subpage = subpage;
        },
        changePageFull (state, pageData) {
            state.page = pageData.page;
            state.subpage = pageData.subpage;
        },

        changeActiveCategoryId (state, categoryId) {
            state.activeCategoryId = categoryId;
        },
        
        // COMPONENT MANAGEMENT
        toggleComponent (state, component: any) {
            // state.pages[component.page].components = [""];
            // @ts-ignore
            if (state.pages[component.page].components.includes(component.name)) {
                console.log("YES< inside");
                let componentList = [];
                // @ts-ignore
                for (let componentName of state.pages[component.page].components) {
                    if (componentName === component.name) {

                    }
                    else {
                        componentList.push(componentName);
                    }
                }
                // @ts-ignore
                state.pages[component.page].components = componentList;
            }
            else {
                // @ts-ignore
                state.pages[component.page].components.push(component.name);
            }
        },
        addComponent (state, component: any) {
            // @ts-ignore
            state.pages[component.page].components.push(component.name);
        },
        removeComponent (state, component: any) {
            // @ts-ignore
            state.pages[component.page].components.splice(state.pages[component.page].components.indexOf(component.name), 1);
        },
        applySet (state, set) {
            // TODO: fill in
        },

        // AUTH MAGIC
        AUTH_INIT(state) {
            if (localStorage.getItem("user")) {
                // @ts-ignore
                state.auth.user = JSON.parse(localStorage.getItem("user"));
            }
            if (localStorage.getItem("key")) {
                // @ts-ignore
                state.auth.key = localStorage.getItem("key");
            }
            state.initialLoad = false;
        },
        AUTH_SET_USER(state, user) {
            state.auth.user = user;
            if (typeof user === "string") {
                localStorage.setItem("user", user);
            }
            else {
                localStorage.setItem("user", JSON.stringify(user));
            }
        },
        AUTH_SET_KEY(state, key) {
            state.auth.key = key;
            if (typeof key === "string") {
                localStorage.setItem("key", key);
            }
            else {
                localStorage.setItem("key", JSON.stringify(key));
            }
        },
        AUTH_CLEAR_ALL(state) {
            localStorage.clear();

            state.auth.user = null;
            state.auth.key = null;

            location.reload();
        },

        setAuthCategory(state, categoryId) {
            state.auth.categoryId = categoryId
        },

        // MY CATEGORY CACHE
        setMyCategoriesCache(state, myCategories) {
            state.myCategoriesCache.list = myCategories
        },
        
        // ALERT CACHE
        setAlertCache(state, alertCache) {
            state.alertCache = alertCache;
        },
        updateAlertCache(state, alertCache) {
            if (alertCache.alertId) state.alertCache.alertId = alertCache.alertId;
            if (alertCache.alert) state.alertCache.alert = alertCache.alert;
            if (alertCache.alertList) state.alertCache.alertList = alertCache.alertList;
        },
        clearAlertCache(state) {
            state.alertCache = {
                alertId: null,
                alert: null,
                alertList: [],
            };
        },
        // CAMPIGN CACHE
        setCampaignCache(state, campaignCache) {
            state.campaignCache = campaignCache;
        },
        updateCampaignCache(state, campaignCache) {
            if (campaignCache.campaignId) state.campaignCache.campaignId = campaignCache.campaignId;
            if (campaignCache.campaign) state.campaignCache.campaign = campaignCache.campaign;
            if (campaignCache.campaignList) state.campaignCache.campaignList = campaignCache.campaignList;
        },
        clearCampaignCache(state) {
            state.campaignCache = {
                campaignId: null,
                campaign: null,
                campaignList: [],
            };
        },
        // CAMPAIGNTYPE CACHE
        setCampaignTypeCache(state, campaignTypeCache) {
            state.campaignTypeCache = campaignTypeCache;
        },
        updateCampaignTypeCache(state, campaignTypeCache) {
            if (campaignTypeCache.campaignTypeId) state.campaignTypeCache.campaignTypeId = campaignTypeCache.campaignTypeId;
            if (campaignTypeCache.campaignType) state.campaignTypeCache.campaignType = campaignTypeCache.campaignType;
            if (campaignTypeCache.campaignTypeList) state.campaignTypeCache.campaignTypeList = campaignTypeCache.campaignTypeList;
        },
        clearCampaignTypeCache(state) {
            state.campaignTypeCache = {
                campaignTypeId: null,
                campaignType: null,
                campaignTypeList: [],
            };
        },
        // CATEGORY CACHE
        setCategoryCache(state, categoryCache) {
            state.categoryCache = categoryCache;
        },
        updateCategoryCache(state, categoryCache) {
            if (categoryCache.categoryId) state.categoryCache.categoryId = categoryCache.categoryId;
            if (categoryCache.category) state.categoryCache.category = categoryCache.category;
            if (categoryCache.categoryList) state.categoryCache.categoryList = categoryCache.categoryList;
        },
        clearCategoryCache(state) {
            state.categoryCache = {
                categoryId: null,
                category: null,
                categoryList: [],
            };
        },
        // CUSTOMER CACHE
        setCustomerCache(state, customerCache) {
            state.customerCache = customerCache;
        },
        updateCustomerCache(state, customerCache) {
            if (customerCache.customerId) state.customerCache.customerId = customerCache.customerId;
            if (customerCache.customer) state.customerCache.customer = customerCache.customer;
            if (customerCache.customerList) state.customerCache.customerList = customerCache.customerList;
        },
        clearCustomerCache(state) {
            state.customerCache = {
                customerId: null,
                customer: null,
                customerList: [],
            };
        },
        // NEGATIVEACTION CACHE
        setNegativeActionCache(state, negativeActionCache) {
            state.negativeActionCache = negativeActionCache;
        },
        updateNegativeActionCache(state, negativeActionCache) {
            if (negativeActionCache.negativeActionId) state.negativeActionCache.negativeActionId = negativeActionCache.negativeActionId;
            if (negativeActionCache.negativeAction) state.negativeActionCache.negativeAction = negativeActionCache.negativeAction;
            if (negativeActionCache.negativeActionList) state.negativeActionCache.negativeActionList = negativeActionCache.negativeActionList;
        },
        clearNegativeActionCache(state) {
            state.negativeActionCache = {
                negativeActionId: null,
                negativeAction: null,
                negativeActionList: [],
            };
        },
        // NEKEYTO CACHE
        setNekeytoCache(state, nekeytoCache) {
            state.nekeytoCache = nekeytoCache;
        },
        updateNekeytoCache(state, nekeytoCache) {
            if (nekeytoCache.nekeytoList) state.nekeytoCache.nekeytoList = nekeytoCache.nekeytoList;
        },
        clearNekeytoCache(state) {
            state.nekeytoCache = {
                nekeytoList: [],
            };
        },
        // SEARCHTERM CACHE
        setSearchTermCache(state, searchTermCache) {
            state.searchTermCache = searchTermCache;
        },
        updateSearchTermCache(state, searchTermCache) {
            if (searchTermCache.searchTermId) state.searchTermCache.searchTermId = searchTermCache.searchTermId;
            if (searchTermCache.searchTerm) state.searchTermCache.searchTerm = searchTermCache.searchTerm;
            if (searchTermCache.searchTermList) state.searchTermCache.searchTermList = searchTermCache.searchTermList;
        },
        clearSearchTermCache(state) {
            state.searchTermCache = {
                searchTermId: null,
                searchTerm: null,
                searchTermList: [],
            };
        },
        // STATUS CACHE
        setStatusCache(state, statusCache) {
            state.statusCache = statusCache;
        },
        updateStatusCache(state, statusCache) {
            if (statusCache.statusId) state.statusCache.statusId = statusCache.statusId;
            if (statusCache.status) state.statusCache.status = statusCache.status;
            if (statusCache.statusList) state.statusCache.statusList = statusCache.statusList;
            if (statusCache.statusStatistics) state.statusCache.statusStatistics = statusCache.statusStatistics;
        },
        clearStatusCache(state) {
            state.statusCache = {
                statusId: null,
                status: null,
                statusList: [],
                statusStatistics: null
            };
        },
        // UNIQUESEARCHTERM CACHE
        setUniqueSearchTermCache(state, uniqueSearchTermCache) {
            state.uniqueSearchTermCache = uniqueSearchTermCache;
        },
        updateUniqueSearchTermCache(state, uniqueSearchTermCache) {
            if (uniqueSearchTermCache.uniqueSearchTermId) state.uniqueSearchTermCache.uniqueSearchTermId = uniqueSearchTermCache.uniqueSearchTermId;
            if (uniqueSearchTermCache.uniqueSearchTerm) state.uniqueSearchTermCache.uniqueSearchTerm = uniqueSearchTermCache.uniqueSearchTerm;
            if (uniqueSearchTermCache.uniqueSearchTermList) state.uniqueSearchTermCache.uniqueSearchTermList = uniqueSearchTermCache.uniqueSearchTermList;
        },
        clearUniqueSearchTermCache(state) {
            state.uniqueSearchTermCache = {
                uniqueSearchTermId: null,
                uniqueSearchTerm: null,
                uniqueSearchTermList: [],
            };
        },
        // AD GROUP TYPE CACHE
        setAdGroupTypeCache(state, adGroupTypeCache) {
            state.adGroupTypeCache = adGroupTypeCache;
        },
        updateAdGroupTypeCache(state, adGroupTypeCache) {
            if (adGroupTypeCache.adGroupType) state.adGroupTypeCache.adGroupType = adGroupTypeCache.adGroupType;
            if (adGroupTypeCache.adGroupTypeList) state.adGroupTypeCache.adGroupTypeList = adGroupTypeCache.adGroupTypeList;
            if (adGroupTypeCache.adGroupTypeId) state.adGroupTypeCache.adGroupTypeId = adGroupTypeCache.adGroupTypeId;
        },
        clearAdGroupTypeCache(state) {
            state.adGroupTypeCache = {
                adGroupTypeId: null,
                adGroupType: null,
                adGroupTypeList: [],
            };
        },

        // MASTER LIST CACHE
        setMasterListCache(state, masterListCache) {
            state.masterListCache = masterListCache;
        },
        updateMasterListCache(state, masterListCache) {
            if (masterListCache.masterListId) state.masterListCache.masterListId = masterListCache.masterListId;
            if (masterListCache.masterList) state.masterListCache.masterList = masterListCache.masterList;
            if (masterListCache.masterListList) state.masterListCache.masterListList = masterListCache.masterListList;
        },
        clearMasterListCache(state) {
            state.masterListCache = {
                masterListId: null,
                masterList: null,
                masterListList: [],
            };
        },
    },
    actions: {
        // LOGIN ACTIONS
        async preLogin(context, email) {
            // context.commit("AUTH_SET_EMAIL", email);
            try {
                const readyData = await fetch(`${context.state.serverAddress}api/v1/auth/checklogin`, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    method: "POST",
                    body: JSON.stringify({ email: email })
                });
                let readyJson = await readyData.json();
                console.log("preLogin", readyJson);
                if (readyJson.data.ready) {
                    return true;
                }
                return false;
            }
            catch (error) {
                console.log("ERROR", error);
                return false;
            }
        },
        async login({ commit }, email) {
            const appKey = await magic.auth.loginWithMagicLink({
                email: email
            });
            commit('AUTH_SET_KEY', appKey);

            const appUser = await magic.user.getMetadata();
            commit('AUTH_SET_USER', appUser);
            
            router.push("/user");
        },
        async logout({ commit }) {
            commit("AUTH_CLEAR_ALL");
            await magic.user.logout();
        },
        async verifyLoggedIn({ commit }) {
            let loggedIn = await magic.user.isLoggedIn();
            if (!loggedIn) {
                commit("AUTH_CLEAR_ALL");
            }
            else {
                magic.user.getIdToken()
                    .then((newKey: any) => {
                        commit("AUTH_SET_KEY", newKey);
                    })
                    .catch((error: any) => {
                        commit("AUTH_CLEAR_ALL");
                    });
            }
        },
    },
    modules: {
    },
    getters: {
    },
});
