// import vue
import Vue from 'vue';
// import app
import App from './App.vue';
// import router
import router from './router';
// import store
import store from './store';

// setup
Vue.config.productionTip = false;

// create new Vue
new Vue({
    router,
    store,
    render: h => h(App),
    beforeCreate () {
        // AUTH_INIT
        this.$store.commit("AUTH_INIT");
    },
    async created() {
        router.push("/");
    }
}).$mount('#app');