// import vue
import Vue from 'vue';
// import vue-router
import VueRouter, { RouteConfig } from 'vue-router';
// import store
import store from '@/store';

// import pages
// other pages will be lazy loaded
import LoginPage from '../views/LoginPage.vue';

// setup vue to use vue router
Vue.use(VueRouter);

// define routes
const routes: Array<RouteConfig> = [
    // USER
    {
        path: '/',
        name: 'UserPage',
        // component: UserPage,
        component: () => import(/* webpackChunkName: "userPage" */ '../views/UserPage.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // HOME
    {
        path: '/home',
        name: 'HomePage',
        // component: HomePage,
        component: () => import(/* webpackChunkName: "homePage" */ '../views/HomePage.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // ALERT
    {
        path: '/alert',
        name: 'AlertPage',
        // component: AlertPage,
        component: () => import(/* webpackChunkName: "alertPage" */ '../views/AlertPage.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // CUSTOMER
    {
        path: '/customer',
        name: 'CustomerPage',
        // component: CustomerPage,
        component: () => import(/* webpackChunkName: "customerPage" */ '../views/CustomerPage.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // LOGIN
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
        meta: {
            requiresAuth: false
        }
    },
    // NEGATIVE ACTION
    {
        path: '/negativeaction',
        name: 'NegativeActionPage',
        // component: NegativeActionPage,
        component: () => import(/* webpackChunkName: "negativeActionPage" */ '../views/NegativeActionPage.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // STATUS
    {
        path: '/status',
        name: 'StatusPage',
        // component: StatusPage,
        component: () => import(/* webpackChunkName: "statusPage" */ '../views/StatusPage.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // USER
    {
        path: '/user',
        name: 'UserPage',
        // component: UserPage,
        component: () => import(/* webpackChunkName: "userPage" */ '../views/UserPage.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // HELP
    {
        path: '/help',
        name: 'HelpPage',
        // component: HelpPage,
        component: () => import(/* webpackChunkName: "helpPage" */ '../views/HelpPage.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // NEGATIVE KEYWORD
    {
        path: '/masterlist',
        name: 'MasterListPage',
        // component: MasterListPage,
        component: () => import(/* webpackChunkName: "masterListPage" */ '../views/MasterListPage.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // NEKEYTO
    {
        path: '/nekeyto',
        name: 'NekeytoPage',
        // component: NekeytoPage,
        component: () => import(/* webpackChunkName: "nekeytoPage" */ '../views/NekeytoPage.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // System Management
    {
        path: '/systemmanagement',
        name: 'SystemManagementPage',
        // component: NekeytoPage,
        component: () => import(/* webpackChunkName: "systemManagementPage" */ '../views/SystemManagementPage.vue'),
        meta: {
            requiresAuth: true
        }
    },
    
    // ALL OTHER 404
    {
        path: '*',
        name: 'ErrorPage',
        // component: ErrorPage,
        component: () => import(/* webpackChunkName: "errorPage" */ '../views/ErrorPage.vue'),
        meta: {
            requiresAuth: false
        }
    },
];

// setup router
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

// check auth before each route
router.beforeEach(async (to, from, next) => {
    // @ts-ignore
    if (to.meta.requiresAuth) {
        if (store.state.auth.user) {
            await store.dispatch("verifyLoggedIn");
            next();
        }
        else {
            next("/login");
        }
    }
    else {
        next();
    }
});

// export
export default router;
